import React, { Component } from "react";
import "../assets/style.css";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  getDeviceSearchData,
  getDevicesUrl,
  errorCompute,
  baseUrl,
} from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { GlobalSettingsType, ActionAssign } from "../Enums";
import Assign from "../components/Assign";
const helpIcon = require("../assets/icons/help_icon.png");
let oldValues = [];
export class DeviceInfo extends Component {
  constructor() {
    super();
    this.state = {
      deviceInfo: [],
      errorMessage: "Something went wrong!",
      eventrows: 6,
      currentPage: 1,
      drows: 10,
      dfirst: 0,
      isGrid: true,
      type: "",
      sProfile: null,
      selectedDevices: null,
      clearOwnFile: false,
      clearFileSD: false,
      eventrows2: 10,
      currentPage2: 1,
      drows2: 10,
      dfirst2: 0,
      msgTitle: "",
      msgBody: "",
      inputError: false,
      length: 0,
    };
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  onDialogueHideError = () => {
    this.setState({ isError: false });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  changeHandler = (e) => {
    console.log("e", e.target);
    this.setState({ [e.target.id]: e.target.value });
  };

  callChangeProfileApi = ({ first = 0, limit = 20 } = {}) => {
    api
      .get(baseUrl + "/global/16/profiles", {
        params: { first: first, limit: limit },
      })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({
            ...res.data.data,
            content2: res.data.data.profiles.content,
            totalRecords2: res.data.data.profiles.totalRecords,
            // drows2: res.data.data.profiles.rows,
          });
        } else {
        }
      });
  };

  callPutDeviceName = (id) => {
    let data = { deviceName: this.state.selectede.deviceName, id: id };
    api.put(baseUrl + "/devices/" + id, data).then((res) => {
      if (res.data.result.isError === false) {
        this.setState({ isLoading: false }, () => {
          this.callDeviceSearchData();
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  actionTemplate(rowData, column) {
    return (
      <div>
        <Link
          style={{ color: "#000000" }}
          to={{
            pathname: "/view-device-info",
            search: "",
            hash: "",
            state: { e: rowData },
          }}
        >
          <Tooltip
            target=".pi-eye"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-eye d-inline-block mr-2"
            style={{ cursor: "pointer", color: "#000000" }}
            data-pr-tooltip="View"
            aria-hidden="true"
          ></i>
        </Link>
        <Tooltip
          target=".pi-pencil"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-pencil d-inline-block mr-2"
          style={{ cursor: "pointer" }}
          data-pr-tooltip="Edit Device Name"
          aria-hidden="true"
          onClick={(event) => {
            // this.callDeleteDevice({ ids: e.id });
            this.setState({ evisible: true, selectede: rowData });
          }}
        ></i>
        <Tooltip
          target=".pi-user-edit"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-user-edit d-inline-block mr-2"
          style={{ cursor: "pointer" }}
          data-pr-tooltip="Change Profile"
          aria-hidden="true"
          onClick={() => {
            this.setState({ profilevisible: true }, () => {
              this.callChangeProfileApi();
            });
          }}
        ></i>
        <Tooltip
          target=".pi-envelope"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-envelope d-inline-block mr-2"
          style={{ cursor: "pointer" }}
          data-pr-tooltip="Push Message"
          aria-hidden="true"
          onClick={() => {
            this.setState({ mvisible: true });
          }}
        ></i>
        <Tooltip
          target=".pi-trash"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          className="pi pi-trash"
          data-pr-tooltip="Delete"
          onClick={(e) => {
            // this.callDeleteDevice({ ids: rowData.id });
            this.setState({ visible: true, selectede: rowData }, () => {
              // this.callDeleteDevice({ ids: rowData.id });
            });
          }}
          style={{ fontSize: "1.3em", cursor: "pointer" }}
        ></i>
        <Tooltip
          target=".pi-ellipsis-v"
          position="top"
          mouseTrack
          mouseTracktop={20}
        />
        <i
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className="pi pi-ellipsis-v ab"
          data-pr-tooltip="More Action"
        >
          <span className="sr-only">Toggle Dropdown</span>
        </i>
        <div className="dropdown-menu">
          <Link
            className="dropdown-item"
            onClick={(e) => {
              this.setState({
                actionvisible: true,
                selectede: rowData,
                type: "Factory Reset",
              });
            }}
          >
            Factory Reset
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => {
              this.setState({
                actionvisible: true,
                selectede: rowData,
                type: "Lock",
              });
            }}
          >
            Lock
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => {
              this.setState({
                actionvisible: true,
                selectede: rowData,
                type: "Deactive Launcher",
              });
            }}
          >
            Deactive Launcher
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => {
              this.setState({
                actionvisible: true,
                selectede: rowData,
                type: "Clear Launcher Data",
              });
            }}
          >
            Clear Launcher Data
          </Link>
          <Link
            className="dropdown-item"
            onClick={() => {
              this.setState({
                actionvisible: true,
                selectede: rowData,
                type: "Clear Files",
              });
            }}
          >
            Clear Files
          </Link>
        </div>
      </div>
    );
  }
  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              this.callgetDevices();
            }
          );
        } else if (res.data.result.isError === true) {
          this.setState(
            {
              // isError: true,
              errorMessage: res.data.result.errorMsg,
            },
            () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
      })
      .catch((err) => {
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callgetDevices = ({
    first = 0,
    limit = this.state.eventrows,
    profileId = null,
    imei = null,
    groupId = null,
  } = {}) => {
    api
      .get(
        getDevicesUrl,
        {
          params: {
            first: first,
            limit: limit,
            profileId: profileId,
            imei: imei,
            groupId: groupId,
          },
        },
        this.setState({ isLoading: true })
      )
      .then((res) => {
        this.setState({
          deviceInfo: res.data.data.content,
          ...res.data.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        errorCompute(err);
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callDeleteDevice = ({ ids }) => {
    api
      .delete(getDevicesUrl, { params: { ids: ids } })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState(
            { isLoading: false, selectede: null, selectedIds: null },
            () => {
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Deleted Device Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      oldValues = [];
                      this.callgetDevices();
                    },
                  },
                ],
              });
              oldValues = [];
              this.callgetDevices();
            }
          );
        } else if (res.data.result.isError) {
          this.setState(
            {
              isLoading: false,
              errorMessage: res.data.result.errorMsg,
            },
            () => {
              confirmAlert({
                title: "Error Message",
                message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
      })
      .catch((err) => {
        errorCompute(err);
      });
  };

  componentDidMount() {
    this.callDeviceSearchData();
  }

  checkedDevice = (id) => (e) => {
    console.log("parameter passed ", id, e.target.checked);
    id["checked"] = e.target.checked;
    if (e.target.checked) oldValues.push(id.id);
    else {
      oldValues.splice(oldValues.indexOf(id.id), 1);
    }
    console.log("OldValues", oldValues.join());
    this.setState({ selectedIds: oldValues.join() });
  };

  globalApiCalls = (type) => {
    let data = {
      data: {
        type: type,
      },
      devices: [this.state.selectede.id],
    };
    switch (type) {
      case 10:
        let content = {
          title: this.state.msgTitle,
          message: this.state.msgBody,
        };
        data["data"]["content"] = content;
        break;
      case 11:
      case 13:
      case 14:
        break;
      case 12:
        content = {
          isLock: true,
          lockPassword: 1234,
        };
        data["data"]["content"] = content;
        break;
      case 15:
        content = {
          clearOwnFiles: this.state.clearOwnFile,
          clearSDFiles: this.state.clearFileSD,
        };
        data["data"]["content"] = content;
        break;
      case 18:
        content = {
          isRemovePassword: true,
          newPassword: "",
          newConfirmPassword: "",
        };
        data["data"]["content"] = content;
        break;
      default:
        break;
    }
    console.log("data", data);
    api.post(`${baseUrl}/global/${data.data.type}/assign`, data).then((res) => {
      if (res.data.result.isError === false) {
        this.setState({ isLoading: false }, () => {
          confirmAlert({
            title: "Success Message",
            message: (
              <p className="mod-sp">
                {type === 11
                  ? "Factory Reset Successfully"
                  : type === 12
                  ? "Device Lock Successfully"
                  : type === 13
                  ? "Deactive Launcher Successfully"
                  : type === 14
                  ? "Clear Launcher Data Successfully"
                  : type === 15
                  ? "Clear Files Successfully"
                  : type === 10
                  ? "Message Sent Successfully"
                  : "Updated Successfully"}
              </p>
            ),
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({
                    msgTitle: "",
                    msgBody: "",
                    inputError: false,
                  });
                },
              },
            ],
          });
        });
      } else {
        this.setState({ isLoading: false }, () => {
          confirmAlert({
            title: "Error Message",
            message: <p className="mod-p">{res.data.result.errorMsg}</p>,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({ msgTitle: "", msgBody: "" });
                },
              },
            ],
          });
        });
      }
    });
  };

  onPageChange2 = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst2: event.first,
        page2: event.page,
        eventrows2: event.rows,
        drows2: event.rows,
        currentPage2: event.page + 1,
      },
      () => {
        this.callChangeProfileApi({
          first: this.state.page2,
          limit: this.state.eventrows2,
        });
      }
    );
  };

  onPageChange = (event) => {
    console.log(event);
    this.setState(
      {
        dfirst: event.first,
        page: event.page,
        eventrows: event.rows,
        rows: event.rows,
        currentPage: event.page + 1,
      },
      () => {
        this.callgetDevices({
          first: this.state.page,
          limit: this.state.eventrows,
          profileId:
            this.state.selectedProfile !== null &&
            this.state.selectedProfile !== undefined
              ? this.state.selectedProfile.id
              : null,
          groupId:
            this.state.selectedGroup !== null &&
            this.state.selectedGroup !== undefined
              ? this.state.selectedGroup.id
              : null,
          imei:
            this.state.imei !== undefined && this.state.imei !== null
              ? this.state.imei
              : null,
        });
      }
    );
  };

  render() {
    console.log(this.state.length);
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Info" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };

    const groupList = this.state.groupList;
    const profileList = this.state.profileList;
    let groupOptions =
      groupList !== undefined &&
      groupList.length > 0 &&
      groupList.map((e, i) => {
        //console.log("group", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    const devicelist = this.state.deviceInfo;
    const deviceinfos = devicelist.map((e, index) => {
      var src = `${process.env.PUBLIC_URL}/data/icons/device` + ".png";
      return (
        <div
          className="col-sm-6 col-md-4 col-lg-4 p-col-12"
          style={{ display: "inline-block" }}
          key={index}
        >
          <div
            style={{
              float: "right",
              paddingRight: "15px",
              marginBottom: "-10px",
            }}
          >
            <Tooltip
              target=".pi-ellipsis-v"
              position="top"
              mouseTrack
              mouseTracktop={20}
            />
            <i
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="pi pi-ellipsis-v ab"
              data-pr-tooltip="More Action"
            >
              <span className="sr-only">Toggle Dropdown</span>
            </i>
            <div className="dropdown-menu">
              <Link
                className="dropdown-item"
                onClick={(v) => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Factory Reset",
                    typev: 11,
                  });
                }}
              >
                Factory Reset
              </Link>
              <Link
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Lock",
                    typev: 12,
                  });
                }}
              >
                Lock
              </Link>
              {/* <Link
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Remove Password",
                    typev: 18,
                  });
                }}
              >
                Remove Password
              </Link> */}
              <Link
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Deactive Launcher",
                    typev: 13,
                  });
                }}
              >
                Deactive Launcher
              </Link>
              <Link
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Clear Launcher Data",
                    typev: 14,
                  });
                }}
              >
                Clear Launcher Data
              </Link>
              <Link
                className="dropdown-item"
                onClick={() => {
                  this.setState({
                    actionvisible: true,
                    selectede: e,
                    type: "Clear Files",
                    typev: 15,
                  });
                }}
              >
                Clear Files
              </Link>
            </div>
            {/* <Assign
              showIcon={true}
              actionType={ActionAssign.ASSIGN}
              name={"Profile Assign"}
              dataToSend={e}
              fromModule="profile"
            /> */}
            <Tooltip
              target=".pi-user-edit"
              position="top"
              mouseTrack
              mouseTracktop={20}
            />
            <i
              className="pi pi-user-edit d-inline-block mr-2"
              style={{ cursor: "pointer" }}
              data-pr-tooltip="Change Profile"
              aria-hidden="true"
              onClick={() => {
                this.setState({ profilevisible: true, selectede: e }, () => {
                  this.callChangeProfileApi();
                });
              }}
            ></i>
            <Tooltip
              target=".pi-envelope"
              position="top"
              mouseTrack
              mouseTracktop={20}
            />
            <i
              className="pi pi-envelope d-inline-block ml-1"
              style={{ cursor: "pointer" }}
              data-pr-tooltip="Push Message"
              aria-hidden="true"
              onClick={() => {
                this.setState({ mvisible: true, selectede: e });
              }}
            ></i>
          </div>

          <div className="device-list">
            <div className="device-imgblcok">
              <img
                src={src}
                alt={e.img}
                width="50px"
                className="device-img mx-auto d-block"
              />
            </div>
            <div className="device-infoblcok">
              <span className="info-title">
                <b>Device Name: </b>
                <Tooltip
                  target=".info-span"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span data-pr-tooltip={e.deviceName} className="info-span">
                  {e.deviceName}
                </span>
              </span>
              <span className="info-title">
                <b>IMEI1: </b>
                <Tooltip
                  target=".info-span"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span data-pr-tooltip={e.imei1} className="info-span">
                  {e.imei1}
                </span>
              </span>
              {e.iemi2 !== null && (
                <span className="info-title">
                  <b>IMEI2: </b>
                  <Tooltip
                    target=".info-span"
                    position="top"
                    mouseTrack
                    mouseTracktop={20}
                  />
                  <span data-pr-tooltip={e.imei2} className="info-span">
                    {e.imei2}
                  </span>
                </span>
              )}
              <span className="info-title">
                <b>Model: </b>
                <Tooltip
                  target=".info-span"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span data-pr-tooltip={e.model} className="info-span">
                  {e.model}
                </span>
              </span>
              {/* <span className="info-title lineup"> */}
              <span className="info-title">
                <b>Profile Name: </b>
                <Tooltip
                  target=".info-span"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span data-pr-tooltip={e.profileName} className="info-span">
                  {e.profileName}
                </span>
              </span>
              <span className="info-title" style={{ marginBottom: "5px" }}>
                <b>Status: </b>
                <Tooltip
                  target=".info-span"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span
                  data-pr-tooltip={
                    e.status !== undefined &&
                    e.status !== null &&
                    e.status === 1
                      ? "Active"
                      : "Inactive"
                  }
                  className="info-span"
                >
                  {e.status !== undefined &&
                  e.status !== null &&
                  e.status === 1 ? (
                    <i
                      className="pi pi-check-circle"
                      style={{
                        color: "green",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="pi pi-times-circle"
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    ></i>
                  )}
                </span>
              </span>
              <span className="device-link">
                <Link
                  style={{ color: "#166A8F" }}
                  to={{
                    pathname: "/view-device-info",
                    search: "",
                    hash: "",
                    state: { e: e },
                  }}
                >
                  <Tooltip
                    target=".pi-eye"
                    position="top"
                    mouseTrack
                    mouseTracktop={20}
                  />
                  <i
                    className="pi pi-eye d-inline-block mr-2"
                    style={{ cursor: "pointer", color: "#166A8F" }}
                    data-pr-tooltip="View"
                    aria-hidden="true"
                  ></i>
                  {/* <button className="btn3" style={{ background: "#166A8F" }}>
                    View
                  </button> */}
                </Link>
                <Tooltip
                  target=".pi-trash"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <i
                  className="pi pi-trash d-inline-block mr-2"
                  style={{ cursor: "pointer", color: "#dc3545" }}
                  data-pr-tooltip="Delete"
                  aria-hidden="true"
                  onClick={(event) => {
                    // this.callDeleteDevice({ ids: e.id });
                    this.setState({ visible: true, selectede: e });
                  }}
                ></i>
                {/* <button
                  className="btn3"
                  style={{ background: "#FB4E4E" }}
                  onClick={(event) => {
                    // this.callDeleteDevice({ ids: e.id });
                    this.setState({ visible: true, selectede: e });
                  }}
                >
                  Delete
                </button> */}
                <Tooltip
                  target=".pi-pencil"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <i
                  className="pi pi-pencil d-inline-block mr-2"
                  style={{ cursor: "pointer", color: "#166A8F" }}
                  data-pr-tooltip="Edit"
                  aria-hidden="true"
                  onClick={(event) => {
                    // this.callDeleteDevice({ ids: e.id });
                    this.setState({ evisible: true, selectede: e });
                  }}
                ></i>
                {/* <button
                  className="btn3"
                  style={{ background: "#166A8F" }}
                  onClick={(event) => {
                    // this.callDeleteDevice({ ids: e.id });
                    this.setState({ evisible: true, selectede: e });
                  }}
                >
                  Edit
                </button> */}
              </span>
            </div>
            <div className="device-check">
              <input
                name="deviceCheck"
                style={{ float: "right", margin: "10px" }}
                type="checkbox"
                checked={
                  e["checked"] !== undefined &&
                  e["checked"] !== null &&
                  e["checked"] === true
                }
                onChange={this.checkedDevice(e)}
              />
            </div>
          </div>
        </div>
      );
    });
    const messagefooter = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ mvisible: false, inputError: false });
          }}
        />
        <Button
          label="Send"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            if (this.state.msgTitle == "" || this.state.msgBody === "") {
              this.setState({ inputError: true });
            } else {
              this.setState({ mvisible: false, isLoading: true }, () => {
                this.globalApiCalls(10);
              });
            }
          }}
          disabled={this.state.length > 1000}
        />
      </div>
    );
    const editfooter = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ evisible: false });
          }}
        />
        <Button
          label="Update"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState({ evisible: false, isLoading: true }, () => {
              console.log("Update");
              this.callPutDeviceName(this.state.selectede.id);
            });
          }}
        />
      </div>
    );
    const messageDialogue = (
      <Dialog
        header="Push Message"
        visible={this.state.mvisible}
        style={{ width: "25vw", zIndex: "1" }}
        modal={true}
        footer={messagefooter}
        onHide={() => this.setState({ mvisible: false, inputError: false })}
      >
        {this.state.inputError === true ? (
          <span
            style={{
              color: "red",
              textAlign: "center",
              fontWeight: "bold",
              paddingLeft: "5px",
            }}
          >
            *Input fields can't be empty
          </span>
        ) : (
          ""
        )}
        <div
          className="form-group p-col-12 mb-0"
          style={{ display: "inline-block" }}
        >
          <label htmlFor="msgTitle">
            Message Title <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="msgTitle"
            placeholder="Enter Message Title"
            onChange={this.changeHandler}
            value={this.state.msgTitle}
            minLength={1}
            required
          />
        </div>
        <div
          className="form-group p-col-12"
          style={{ display: "inline-block" }}
        >
          <label htmlFor="msgBody">
            Message Body <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            className="form-control"
            rows="4"
            id="msgBody"
            placeholder="Enter Your Message"
            // onChange={this.changeHandler}
            onChange={(e) => {
              // this.changeHandler();
              this.setState({
                length: e.target.value.length,
                msgBody: e.target.value,
              });
            }}
            value={this.state.msgBody}
            required
          ></textarea>
          {this.state.length > 1000 && (
            <p style={{ color: "red", padding: "10px" }}>
              Maximum character limit 1000
            </p>
          )}
        </div>
      </Dialog>
    );
    const editDialogue = (
      <Dialog
        header="Update Device Name"
        visible={this.state.evisible}
        style={{ width: "25vw" }}
        modal={true}
        footer={editfooter}
        onHide={() => this.setState({ evisible: false })}
      >
        <div
          className="form-group p-col-12"
          style={{ display: "inline-block" }}
        >
          <label htmlFor="deviceName">Device Name</label>
          <input
            type="text"
            className="form-control"
            id="deviceName"
            placeholder="Enter Device Name"
            onChange={(e) => {
              let selectede = {
                ...this.state.selectede,
                deviceName: e.target.value,
              };
              this.setState({ selectede: selectede });
            }}
            value={
              this.state.selectede !== undefined &&
              this.state.selectede !== null &&
              this.state.selectede.deviceName
            }
          />
        </div>
      </Dialog>
    );
    const actionfooter = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ actionvisible: false });
          }}
        />
        <Button
          label="Submit"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState({ actionvisible: false, isLoading: true }, () => {
              console.log("Global Api call");
              this.globalApiCalls(this.state.typev);
            });
          }}
        />
      </div>
    );
    const actionDialogue = (
      <Dialog
        header={this.state.type}
        visible={this.state.actionvisible}
        style={{ width: "25vw" }}
        modal={true}
        footer={actionfooter}
        onHide={() => this.setState({ actionvisible: false })}
      >
        {this.state.type === "Clear Files" ? (
          <div className="col-12">
            <label htmlFor="messageTitle">
              <b>Clear Files Options</b>
            </label>
            <div className="form-group mt-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="clearOwnFile"
                  id="clearOwnFile"
                  value="option1"
                  onChange={(e) => {
                    this.setState({ clearOwnFile: e.target.checked });
                  }}
                />
                <label className="form-check-label" for="clearOwnFile">
                  Clear Own Files
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="clearFileSD"
                  name="clearFileSD"
                  value="option2"
                  onChange={(e) => {
                    this.setState({ clearFileSD: e.target.checked });
                  }}
                />
                <label className="form-check-label" for="clearFileSD">
                  Clear All Files from SD Card
                </label>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center">
            Do you Want to <b>{this.state.type}</b> ?
          </p>
        )}
      </Dialog>
    );
    const profilefooter = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ profilevisible: false });
          }}
        />
        <Button
          label="Submit"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            if (this.state.sId === undefined) {
              this.setState({ isLoading: false, profilevisible: false }, () => {
                confirmAlert({
                  title: "Error Message",
                  message: <p className="mod-p">Please Select Profile</p>,
                  buttons: [
                    {
                      label: "Ok",
                      onClick: () => {
                        this.setState({ profilevisible: true });
                      },
                    },
                  ],
                });
              });
            } else {
              this.setState({ profilevisible: false, isLoading: true }, () => {
                console.log("Global Api call");
                let data = {
                  criteria: 2,
                  data: this.state.sId,
                  devices: [
                    this.state.isGrid == true
                      ? this.state?.selectede.id
                      : this.state?.sId,
                  ],
                };
                let urlToPost =
                  baseUrl + /profile-list/ + data.data + "/assign";
                console.log("urltoPost", urlToPost, "data", data);
                api.post(urlToPost, data).then((res) => {
                  if (res.data.result.isError === false) {
                    this.setState({ isLoading: false }, () => {
                      confirmAlert({
                        title: "Success Message",
                        message: (
                          <p className="mod-sp">
                            {" "}
                            Profile Change Successfully{" "}
                          </p>
                        ),
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {
                              window.location = `${process.env.PUBLIC_URL}/device-info`;
                            },
                          },
                        ],
                      });
                    });
                  } else {
                    this.setState({ isLoading: false }, () => {
                      confirmAlert({
                        title: "Error Message",
                        message: (
                          <p className="mod-p"> {res.data.result.errorMsg} </p>
                        ),
                        buttons: [
                          {
                            label: "Ok",
                            onClick: () => {},
                          },
                        ],
                      });
                    });
                  }
                });
              });
            }
          }}
        />
      </div>
    );
    const profileDialogue = (
      <Dialog
        header="Change Profile"
        visible={this.state.profilevisible}
        style={{ width: "35vw" }}
        modal={true}
        footer={profilefooter}
        onHide={() => this.setState({ profilevisible: false })}
      >
        <div
          className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input mb-2"
          style={{ padding: "3px 0px" }}
        >
          <input
            className="form-control form-control-sm mr-2 b-n"
            type="text"
            placeholder="Enter Search Terms"
            aria-label="Search"
            style={{ width: "90%" }}
            onClick={() => {}}
          />
          <i className="pi pi-search" aria-hidden="true"></i>
        </div>
        <DataTable
          value={this.state.content2}
          emptyMessage="No records found"
          paginator
          lazy
          onPage={(event) => {
            this.onPageChange2(event);
          }}
          first={this.state.dfirst2}
          currentPage={this.state.currentPage2}
          totalRecords={this.state.totalRecords2}
          selection={this.state.sProfile}
          onSelectionChange={(e) =>
            this.setState({ sProfile: e.value, sId: e.value.assignedObject.id })
          }
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          rows={this.state.drows2}
          rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
          dataKey="assignedObject.id"
        >
          <Column
            selectionMode="single"
            headerStyle={{ width: "3em" }}
          ></Column>
          <Column field="assignedObject.name" header="Profile Name"></Column>
        </DataTable>
      </Dialog>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState({ visible: false, isLoading: true }, () => {
              this.callDeleteDevice({ ids: this.state.selectede.id });
            });
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Device"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete this Device?
        </p>
      </Dialog>
    );
    const footer1 = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={(e) => {
            this.setState(
              { visible1: false, isLoading: true, ids: oldValues.join() },
              () => {
                this.callDeleteDevice({ ids: oldValues.join() });
              }
            );
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible1: false });
          }}
        />
      </div>
    );
    const deleteAllDialogue = (
      <Dialog
        header="Delete Device"
        visible={this.state.visible1}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer1}
        onHide={() => this.setState({ visible1: false })}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingBottom: "10px",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Do you want to Delete Selected Devices?
        </p>
      </Dialog>
    );
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          {editDialogue}
          {deleteDialogue}
          {deleteAllDialogue}
          {messageDialogue}
          {actionDialogue}
          {profileDialogue}
          <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              alt="Help"
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "20px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Device Info</b>
              </div>
              <div className="helptip-des">
                With this panel, users can monitor individual device’s status,
                location, available apps etc.
              </div>
            </div>
          </OverlayPanel>
          <Dialog
            visible={this.state.isLoading}
            modal={true}
            style={{ width: "20vw" }}
            closable={false}
            showHeader={false}
            onHide={this.onDialogueHide}
          >
            <Loader />
          </Dialog>
          <Dialog
            header="Success!"
            visible={this.state.isSuccess}
            modal={true}
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideSuccess}
          >
            <SuccessDialog successMessage="Deleted Device Successfully" />
          </Dialog>
          <Dialog
            header="Error!"
            visible={this.state.isError}
            modal
            style={{ width: "25vw" }}
            closable
            showHeader
            onHide={this.onDialogueHideError}
          >
            <ErrorDialog errorMessage={this.state.errorMessage} />
          </Dialog>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col-12 p-lg-12 ">
            <div
              className="p-col-12 p-lg-3 p-md-3 "
              style={{ display: "inline-block", padding: "5px" }}
            >
              <div
                id="cus-from"
                className="form-inline d-flex justify-content-center md-form form-sm mt-0 b-input"
              >
                <input
                  className="form-control mr-2 b-n"
                  type="text"
                  placeholder="IMEI or Device Name"
                  aria-label="Search"
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    this.setState({ imei: e.target.value });
                  }}
                />
                <i className="pi pi-search" aria-hidden="true"></i>
              </div>
            </div>
            <div
              className="p-col-12 p-lg-2 p-md-2"
              style={{ display: "inline-block", padding: "5px" }}
            >
              <div className="form-group">
                <select
                  className="form-control"
                  // id="exampleFormControlSelect1"
                  onChange={(e) => {
                    console.log("e in profile ", e.target);
                    let profileObject = profileList.find((obj) => {
                      console.log("object", obj.id);
                      return obj.id === e.target.value;
                    });
                    // console.log("profile object", profileObject);
                    this.setState({
                      ...this.state,
                      selectedProfile: profileObject,
                    });
                  }}
                  id="selectedProfile"
                  name="selectedProfile"
                  value={
                    this.state.selectedProfile !== null &&
                    this.state.selectedProfile !== undefined
                      ? this.state.selectedProfile.id
                      : ""
                  }
                >
                  <option>Select Profile</option>
                  {profileOptions}
                </select>
              </div>
            </div>
            <div
              className="p-col-12 p-lg-2 p-md-2"
              style={{ display: "inline-block", padding: "5px" }}
            >
              <div className="form-group">
                <select
                  className="form-control"
                  onChange={(e) => {
                    let groupobject = groupList.find((obj) => {
                      return obj.id === e.target.value;
                    });
                    this.setState({
                      ...this.state,
                      selectedGroup: groupobject,
                    });
                  }}
                  id="selectedGroup"
                  name="selectedGroup"
                  value={
                    this.state.selectedGroup !== undefined &&
                    this.state.selectedGroup !== null
                      ? this.state.selectedGroup.id
                      : ""
                  }
                >
                  <option>Select Group</option>
                  {groupOptions}
                </select>
              </div>
            </div>
            <div
              className="p-col-8 p-lg-3 p-md-3"
              style={{ display: "inline-block" }}
            >
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-s"
                  onClick={(e) => {
                    this.callgetDevices({
                      profileId:
                        this.state.selectedProfile !== null &&
                        this.state.selectedProfile !== undefined
                          ? this.state.selectedProfile.id
                          : null,
                      groupId:
                        this.state.selectedGroup !== null &&
                        this.state.selectedGroup !== undefined
                          ? this.state.selectedGroup.id
                          : null,
                      imei:
                        this.state.imei !== undefined &&
                        this.state.imei !== null
                          ? this.state.imei
                          : null,
                    });
                  }}
                >
                  Search
                </button>
                <i
                  className="pi pi-list ml-2"
                  style={{
                    fontSize: "1.5em",
                    paddingRight: "8px",
                    paddingTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ isGrid: false });
                  }}
                ></i>
                <i
                  className="pi pi-th-large"
                  style={{
                    fontSize: "1.5em",
                    paddingLeft: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ isGrid: true });
                  }}
                ></i>
              </div>
            </div>
            <div
              className="p-col-4 p-lg-2 p-md-2"
              style={{ display: "inline-block", padding: "5px" }}
            >
              <div className="form-group" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {
                    this.setState({ visible1: true, selectede: e }, () => {
                      // this.callDeleteDevice({ ids: oldValues.join() });
                    });
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          {this.state.isGrid ? (
            <div
              className="p-col"
              // style={{
              //   height: "420px",
              //   overflowX: "hidden",
              //   overflowY: "scroll",
              // }}
            >
              {deviceinfos}
              <div className="p-col-12" id="cus-padding">
                {/* {entApplist} */}
                <Paginator
                  first={this.state.dfirst}
                  rows={this.state.eventrows}
                  totalRecords={this.state.totalRecords}
                  rowsPerPageOptions={[6, 10, 20, 30, 50, 100]}
                  onPageChange={this.onPageChange}
                ></Paginator>
              </div>
            </div>
          ) : (
            <div className="card">
              <DataTable
                value={this.state.deviceInfo}
                emptyMessage="No records found"
                paginator
                lazy
                onPage={(event) => {
                  setTimeout(() => {
                    const { first, rows, page } = event;
                    this.setState(
                      {
                        dfirst: first,
                        page,
                        drows: rows,
                        currentPage: page + 1,
                      },
                      () => {
                        // let response = await this.callgetHistoryLocation({
                        //   first: event.page,
                        //   limit: this.state.drows,
                        // });
                        // this.setState({ ...response });
                        this.setState({ flag: false });
                        this.callgetDevices({
                          first: event.page,
                          limit: this.state.drows,
                          profileId:
                            this.state.selectedProfile !== null &&
                            this.state.selectedProfile !== undefined
                              ? this.state.selectedProfile.id
                              : null,
                          groupId:
                            this.state.selectedGroup !== null &&
                            this.state.selectedGroup !== undefined
                              ? this.state.selectedGroup.id
                              : null,
                          imei:
                            this.state.imei !== undefined &&
                            this.state.imei !== null
                              ? this.state.imei
                              : null,
                        });
                      }
                    );
                  }, 500);
                }}
                first={this.state.dfirst}
                currentPage={this.state.currentPage}
                totalRecords={this.state.totalRecords}
                selection={this.state.selectedDevices}
                onSelectionChange={(e) => {
                  this.setState({ selectedDevices: e.value }, () => {
                    oldValues = [];
                    this.state.selectedDevices.map((e, i) => {
                      oldValues.push(e.id);
                    });
                  });
                }}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rows={this.state.drows}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>
                <Column field="deviceName" header="Device Name"></Column>
                <Column field="imei1" header="IMEI1"></Column>
                <Column field="imei2" header="IMEI2"></Column>
                <Column field="model" header="Model"></Column>
                <Column field="profileName" header="Profile Name"></Column>
                <Column
                  header="Action"
                  body={this.actionTemplate.bind(this)}
                  style={{ textAlign: "center", width: "20%" }}
                />
              </DataTable>
            </div>
          )}
        </div>
      </div>
    );
  }
}
